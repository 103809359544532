import React, { useState, useEffect } from 'react';
import * as Constants from '../constants';
import { motion } from 'framer-motion';
import Date from '../components/Date.jsx';
import Weather from '../components/Weather';
import NavMain from '../components/NavMain';
import { Link, useLocation } from 'react-router-dom';
import packageJson from "../../package.json"

// Icons
import { ImSortAlphaAsc } from 'react-icons/im';
import { ImSun } from 'react-icons/im';
import {GiSlicedBread} from 'react-icons/gi'
import {FaCalendarAlt} from 'react-icons/fa'
import {FaBus} from 'react-icons/fa'
import {FaHiking} from 'react-icons/fa'
import {BiNews} from 'react-icons/bi'
import {FiShoppingCart} from 'react-icons/fi'
import {AiFillStar} from 'react-icons/ai'
import {BsCardHeading} from 'react-icons/bs'
import {GrContact} from 'react-icons/gr'
import {MdRestaurant} from 'react-icons/md'
import {IoMdFlower} from 'react-icons/io'
import {MdDirectionsBike} from 'react-icons/md'

function GetHome() {
	// scroll to top on each page change
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<div className="home">
			<motion.div
			initial={{x: '-100vw'}}
			animate='in'
			exit={{x: '-100vw'}}
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			>
				<img className="logo" src="./images/logo.svg" />
				<Date />
				<Weather />
				<nav className="main">
					<ul className="flex-center flex-wrap">
						<NavMain pathname="/info" title="Info A-Z" icon={<ImSortAlphaAsc />} />
						<NavMain pathname="/bread" title={Constants.breadLabel[Constants.lang]} icon={<GiSlicedBread />} />
						<NavMain pathname="/gourmet-boutique" title="Gourmet Boutique" icon={<FiShoppingCart />} />
						<NavMain pathname="/rental" title={Constants.rentalServiceLabel[Constants.lang]} icon={<MdDirectionsBike />} />
						<NavMain pathname="/morning-post" title={Constants.morningPostLabel[Constants.lang]} icon={<BiNews />} />
						<NavMain pathname="/forecast" title={Constants.weatherLabel[Constants.lang]} icon={<ImSun />} />
						<NavMain pathname="/favorits" title="Favorits" icon={<AiFillStar />} />
						<NavMain pathname="/active" title="Active" icon={<FaHiking />} />
						<NavMain pathname="/events" title="Events" icon={<FaCalendarAlt />} />
						<NavMain pathname="/restaurants" title={Constants.restaurantsLabel[Constants.lang]} icon={<MdRestaurant />} />
						{/**
						 * 1. April - 31. Mai Schenna blüht auf
						 */
						Constants.schennaInBloomBegin <= Constants.date && Constants.schennaInBloomEnd >= Constants.date
							?
								<li className="link flex-center align-center">
									<Link target="_blank" to={{pathname:Constants.schennaInBloomLink[Constants.lang], title:Constants.schennaInBloom[Constants.lang]}} className="flex-center align-center flex-column">
										<div className="circle flex-center align-center">
											<IoMdFlower />
										</div>
										<div className="title small">{Constants.schennaInBloom[Constants.lang]}</div>
									</Link>
								</li>
							: ''
						}
						{/* <NavMain pathname="/bus" title={Constants.busLabel[Constants.lang]} icon={<FaBus />} /> */}
						<NavMain pathname="/contact" title={Constants.contactLabel[Constants.lang]} icon={<GrContact />} />
					</ul>
				</nav>
				<div className="version small">v{packageJson.version}</div>
			</motion.div>
		</div>
	)
}
export default GetHome;