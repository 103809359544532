export let baseUrl = window.location.origin
if (window.location.hostname === 'localhost' || window.location.hostname === '192.168.4.154') baseUrl = 'http://localhost:9000';
export const cms = 'https://cockpit.dornsberg.net'

export const date = new Date();
export let day = date.getDate();
if (day < 10) day = '0' + day;
export let tomorrow = date.getDate() + 1;
if (tomorrow < 10) tomorrow = '0' + tomorrow;
export let month = date.getMonth() + 1;
if (month < 10) month = '0' + month;
export const year = date.getFullYear();
export let weekday = []
weekday[0] = {
	de: 'Sonntag',
	it: 'Domenica',
	en: 'Sunday'
}
weekday[1] = {
	de: 'Montag',
	it: 'Lunedì',
	en: 'Monday'
}
weekday[2] = {
	de: 'Dienstag',
	it: 'Martedì',
	en: 'Tuesday'
}
weekday[3] = {
	de: 'Mittwoch',
	it: 'Mercoledì',
	en: 'Wednesday'
}
weekday[4] = {
	de: 'Donnerstag',
	it: 'Giovedì',
	en: 'Thursday'
}
weekday[5] = {
	de: 'Freitag',
	it: 'Venerdì',
	en: 'Friday'
}
weekday[6] = {
	de: 'Samstag',
	it: 'Sabato',
	en: 'Saturday'
}

// PAGE TRANSITION //
export const pageTransition = {
	initial: {
		x: '100vw',
		opacity: 0
	},
	in: {
		x: 0,
		opacity: 1
	},
	out: {
		x: '100vw',
		opacity: 0
	},
	transition: {
		duration: .25,
		ease: 'linear'
	},
	style: {
		position: "absolute",
		width: '100%',
		padding: '5%'
	}
};

// TRANSLATIONS //

export let lang = navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2);
// default lang is en
if (lang !== 'de' && lang !== 'it' && lang !== 'en') lang = 'en';
// change html lang tag
document.documentElement.lang = lang;
export const todayLabel = {
	de: 'Heute',
	it: 'Oggi',
	en: 'Today'
}
export const tomorrowLabel = {
	de: 'Morgen',
	it: 'Domani',
	en: 'Tomorrow'
}
export const weatherLabel = {
	de: 'Wetter',
	it: 'Meteo',
	en: 'Weather'
}
export const weatherEvolution = {
	de: 'Vorschau auf die nächsten Tage',
	it: 'Previsione per i prossimi giorni',
	en: 'Outlook for the next days'
}
export const precipitationLabel = {
	de: 'Regenwahrscheinlichkeit',
	it: 'Probabilità di precipitazioni',
	en: 'Probability of precipitation'
}
export const clockLabel = {
	de: 'Uhr',
	it: 'ore',
	en: 'o\'clock'
}
export const lightRain = {
	de: 'leichter Regen',
	it: 'pioggia leggera',
	en: 'light rain'
}
export const moderateRain = {
	de: 'mäßiger Regen',
	it: 'pioggia moderata',
	en: 'moderate rain'
}
export const heavyRain = {
	de: 'starker Regen',
	it: 'pioggia forte',
	en: 'heavy rain'
}
export const probability1 = {
	de: 'möglich',
	it: 'possibile',
	en: 'possible'
}
export const probability2 = {
	de: 'wahrscheinlich',
	it: 'probabile',
	en: 'probable'
}
export const probability3 = {
	de: 'sehr wahrscheinlich',
	it: 'molto probabile',
	en: 'highly probable'
}
export const thunderstormsLabel = {
	de: 'Gewitter',
	it: 'temporali',
	en: 'thunderstorms'
}
export const mountainWeather = {
	de: 'Bergwetter',
	it: 'Meteo per la montagna',
	en: 'Mountain weather'
}
export const sourceLabel = {
	de: 'Quelle',
	it: 'Sorgente',
	en: 'Source'
}
export const breadLabel = {
	de: 'Brot bestellen',
	it: 'Ordinare pane',
	en: 'Order bread'
}
export const busLabel = {
	de: 'Busfahrplan',
	it: 'Orario autobus',
	en: 'Bus timetable'
}
export const authScanQrCode = {
	de: 'Diese App steht ausschließlich Gästen der Residence Dornsberg Panoramic Apartments zur Verfügung.<br/><br/>\
	Scannen Sie den QR-Code auf dem Willkommensbrief, um diese App freizuschalten.',
	it: 'Questa applicazione è disponibile solo per gli ospiti del Residence Dornsberg Panoramic Apartments.<br/><br/>\
	Per attivare l\'app vi preghiamo di scansionare il codice QR che si trova sulla lettera di benvenuto.',
	en: 'This application is dedicated only to guest of the Residence Dornsberg Panoramic Apartments.<br/><br/>\
	To activate this app, pleace scan the QR code on the welcome letter.'
}
export const authScanButton = {
	de: 'Jetzt scannen',
	it: 'Scansionare adesso',
	en: 'Scan now'
}
export const authSanAbort = {
	de: 'Es funktioniert nicht',
	it: 'Non funziona',
	en: 'It doesn\'t work'
}
export const authWithIp = {
	de: 'Bitte verbinden Sie sich mit dem WLAN "Dornsberg", um diese App freizuschalten. Das WLAN-Passwort finden Sie im Willkommensbrief.',
	it: 'Per attivare l\'app vi preghiamo di connettere con il Wi-Fi "Dornsberg". La password si trova nella lettera di benvenuto.',
	en: 'To activate this app, pleace connect to the Wi-Fi "Dornsberg". The password you can find in the welcome letter.'
}
export const authText = {
	de: 'Diese App steht ausschließlich Gästen der Residence Dornsberg Panoramic Apartments zur Verfügung.<br/><br/>\
	Verbinden Sie sich mit dem WLAN "Dornsberg", um diese App freizuschalten. Das WLAN-Passwort finden Sie im Willkommensbrief.',
	it: 'Questa applicazione è disponibile solo per gli ospiti del Residence Dornsberg Panoramic Apartments.<br/><br/>\
	Per attivare l\'app si deve connettere con il Wi-Fi "Dornsberg". La password si trova nella lettera di benvenuto.',
	en: 'This application is dedicated only to guest of the Residence Dornsberg Panoramic Apartments.<br/><br/>\
	To activate this app, pleace connect to the Wi-Fi "Dornsberg". The password you can find in the welcome letter.'
}
export const authButton = {
	de: 'Nochmal versuchen',
	it: 'Riprovare',
	en: 'Try again'
}
export const authAgain = {
	de: 'Verbinden Sie sich mit dem WLAN "Dornsberg", um diese App erneut freizuschalten.',
	it: 'Connettere di nuovo col Wi-Fi "Dornsberg" per riattivare questa applicazione.',
	en: 'Connect to the Wi-Fi "Dornsberg" to use this app again.'
}
export const readMore = {
	de: 'Weiterlesen',
	it: 'Leggi di più',
	en: 'Read more'
}
export const discoverMore = {
	de: 'Mehr erfahren',
	it: 'Scopri di più',
	en: 'Discover more'
}
export const dearFamily = {
	de: 'Liebe Familie',
	it: 'Cara famiglia',
	en: 'Dear family'
}
// BREAD
export const breadText = {
	de: 'Als Ergänzung zu Ihrem Frühstück versorgen wir Sie gerne von Montag bis Samstag (<b>keine Brotlieferungen am Sonntag möglich</b>) mit frischen Brötchen vom Bäcker. Eine Bestellung können Sie hier oder in der Gourmet Boutique <b>bis 18 Uhr</b> des Vortages aufgeben.',
	it: 'Come aggiunta alla vostra prima colazione vi forniremo volentieri dal lunedì al sabato (<b>eccetto la domenica</b>) i panini freschi dal panettiere. L\'ordine può effettuato qui o nella Gourmet Boutique <b>entro le ore 18</b> del giorno precedente',
	en: 'Every morning we offer you fresh bread from our bakery. You can order here or at the Gourmet Boutique the bread <b>until 6 pm</b> at the previous day.'
}
export const letsGo = {
	de: 'Los geht\'s',
	it: 'Avanti',
	en: 'Let\'s go'
}
export const priceLabel = {
	de: 'Preis',
	it: 'Prezzo',
	en: 'Price'
}
export const qnt = {
	de: 'Menge',
	it: 'Quantità',
	en: 'Quantity'
}
export const orderNow = {
	de: 'Bestellung absenden',
	it: 'Invia ordine',
	en: 'Submit order'
}
export const apartment = {
	de: 'Apartment',
	it: 'Appartamento',
	en: 'Apartment'
}
export const choose = {
	de: 'Bitte wählen',
	it: 'Prego selezionare',
	en: 'Please choose'
}
export const order = {
	de: 'Brotbestellung für',
	it: 'Ordine del pane per',
	en: 'Bread order for'
}
export const breadOrderTooLate = {
	de: 'Brotbestellungen können nur bis 18 Uhr aufgegeben werden! Wir bitten um Verständnis.',
	it: 'Ordini del pane possono essere accettati solo entro le ore 18! Ci scusiamo per il disagio.',
	en: 'Bread orders can only be placed until 6 pm! We apologise for any inconvenience.'
}
export const breadOrderNotPossible = {
	de: 'Brotbestellungen für morgen nicht möglich, da sonntags kein Brot geliefert wird. Bitte bestellen Sie morgen wieder.',
	it: 'Nessuna consegna per la domenica. Riprova domani per favore.',
	en: 'Bread orders for tomorrow are not possible because no bread will be delivered on Sunday. Please order again tomorrow.'
}
export const repeat = {
	de: 'Bestellung wiederholen bis auf Widerruf',
	it: 'Ripettere l\'ordine fino a nuovo ordine',
	en: 'Repeat order until revoked'
}
export const proceed = {
	de: 'Weiter',
	it: 'Procedere',
	en: 'Proceed'
}
export const noApartment = {
	de: 'Kein Apartment ausgewählt',
	it: 'Nessun appartamento selezionato',
	en: 'No apartment selected'
}
export const noBread = {
	de: 'Kein Brot gewählt',
	it: 'Nessun pane selezionato',
	en: 'No bread selected'
}
export const total = {
	de: 'Gesamtsumme',
	it: 'Totale',
	en: 'Total amount'
}
export const summaryLabel = {
	de: 'Zusammenfassung',
	it: 'Panoramica',
	en: 'Summary'
}
export const stop = {
	de: 'Ändern',
	it: 'Modifica',
	en: 'Modify'
}
export const orderSuccess = {
	de: 'Vielen Dank für Ihre Bestellung! Wir hängen das frische Brot morgen gegen 7 Uhr an Ihre Türklinke und buchen diese Bestellung auf Ihre Zimmerrechnung. Den leeren Brotsack können Sie im Eingangsbereich abgeben. Danke!',
	it: 'Grazie mille per l\'ordine! Mettiamo il pane fresco domani verso le ore 7 alla Vostra maniglia e aggiungiamo l\'ordine al Vostro conto. Il sacchetto vuoto del pane può essere consegnato all\'ingresso. Grazie!',
	en: 'Thank you for your order! Tomorrow at about 7 am we put the fresh bread on your door handle and we put this order on your bill. The empty bag you can return at the entry hall. Thank you!'
}
export const returnHome = {
	de: 'Startseite',
	it: 'Pagina iniziale',
	en: 'Home page'
}
// SHOP
export const priceDisclaimer = {
	de: 'inkl. MwSt.',
	it: 'IVA inclusa',
	en: 'VAT included'
}
export const shop = {
	de: 'Im Stockwerk 0 befindet sich unsere kleine aber feine Gourmet Boutique mit ausgewählten, regionalen Spezialitäten höchster Qualität (keine Industriewaren). Mithilfe des Tablets können Sie dort die gewünschten Artikel selbst einkaufen.',
	it: 'Al piano 0 si trova la nostra piccola Gourmet Boutique con delle selezionate specialità regionali di alta qualità. Utilizzando il tablet Lei puo comprare gli articoli desiderati.',
	en: 'On floor 0 there is our small Gourmet Boutique with selected high quality regional specialties. Using the tablet you can buy the desired items.'
}
// EVENTS
export const eventIntro = {
	de: 'Hier die nächsten 10 Veranstaltungen in Schenna.',
	it: 'Ecco i prossimi 10 eventi a Scena.',
	en: 'Here are the next 10 events at Scena.'
}
export const moreInfoLabel = {
	de: 'Für weitere Infos',
	it: 'Per altri informazioni',
	en: 'For more information'
}
export const touristOffice = {
	de: 'Tourismusbüro Schenna',
	it: 'Uffico Turistico Scena',
	en: 'Turist Information Schenna'
}
// MORNING POST
export const morningPostLabel = {
	de: 'Morgenpost',
	it: 'Giornale di Scena',
	en: 'Morning post'
}
export const morningPostUrl = {
	de: 'https://www.merano-suedtirol.it/pages/morningPost.aspx?lang=de&config=Schenna&date=',
	it: 'https://www.merano-suedtirol.it/pages/morningPost.aspx?lang=it&config=Schenna&date=',
	en: 'https://www.merano-suedtirol.it/pages/morningPost.aspx?lang=en&config=Schenna&date='
}
// GUEST CARD
export const guestCardBegin = new Date(year + '-07-01')
export const guestCardEnd = new Date(year + '-10-14')
export const guestCardUrl = {
	de: 'https://www.merano-suedtirol.it/de/urlaubsplanung/vorteilskarten/guestcard.html',
	it: 'https://www.merano-suedtirol.it/it/pianificare-le-vacanze/carte-vantaggi/guestcard.html',
	en: 'https://www.merano-suedtirol.it/en/vacation-planning/experience-and-advantage-cards/guestcard.html'
}
// MERAN CARD
export const meranCardUrl = {
	de: 'https://www.merano-suedtirol.it/de/urlaubsplanung/vorteilskarten/merancard.html',
	it: 'https://www.merano-suedtirol.it/it/pianificare-le-vacanze/carte-vantaggi/merancard.html',
	en: 'https://www.merano-suedtirol.it/en/vacation-planning/experience-and-advantage-cards/merancard.html'
}
// SCHENNA BLÜHT AUF
export const schennaInBloomBegin = new Date(year + '-04-01')
export const schennaInBloomEnd = new Date(year + '-05-31')
export const schennaInBloom = {
	de: 'Schenna blüht auf',
	it: 'Scena in fiore',
	en: 'Schenna in bloom'
}
export const schennaInBloomLink = {
	de: 'https://www.merano-suedtirol.it/de/schenna/info-service/veranstaltungen/schenna-blueht-auf.html',
	it: 'https://www.merano-suedtirol.it/it/scena/informazioni-servizi/eventi/scena-in-fiore.html',
	en: 'https://www.merano-suedtirol.it/en/scena/info-service/events/schenna-in-bloom.html'
}
// CONTACT
export const contactLabel = {
	de: 'Kontakt',
	it: 'Contatto',
	en: 'Contact'
}
export const contactIntro = {
	de: 'Sie erreichen uns per Haustelefon mit der Durchwahlnummer 200, auf dem Festnetz +39 0473 94 30 28, per WhatsApp +39 0473 94 30 28 oder per E-Mail info@dornsberg.net. Gerne können Sie auch im Stockwerk 1 bei der Rezeption klingeln.',
	it: 'Vi preghiamo di contattarci con il numero di estensione 200, numero di rete fissa 0473 94 30 28, WhatsApp 0473 94 30 28 o e-mail info@dornsberg.net. Oppure potete suonare il campanello alla reception al piano 1.',
	en: 'You can contact us with the internal phone number 200, on landline with +39 0473 94 30 28, with WhatsApp +39 0473 94 30 28 or with email info@dornsberg.net. Or you can ring the bell at the reception at the 1st floor.'
}
export const phoneLabel = {
	de: 'Telefon',
	it: 'Telefono',
	en: 'Phone'
}
export const mailLabel = {
	de: 'E-Mail',
	it: 'Email',
	en: 'Email'
}
// RESTAURANTS
export const restaurantsIntro = {
	de: 'Hier alle Restaurants in Schenna auf einer Seite.',
	it: 'Qui tutti i ristoranti a Scena su una pagina.',
	en: 'Here all restaurants at Scena on one page.'
}
export const restaurantsLabel = {
	de: 'Restaurants',
	it: 'Ristoranti',
	en: 'Restaurants'
}
export const addressDornsberg = {
	de: 'St. Georgener Str. 32b<br/>39017 Schenna',
	it: 'Via San Giorgio 32b<br/>39017 Scena',
	en: 'Via San Giorgio 32b<br/>39017 Scena'
}
export const openingHours = {
	de: 'Öffnungszeiten',
	it: 'Orari di apertura',
	en: 'Opening hours'
}
export const openLabel = {
	de: 'geöffnet',
	it: 'aperto',
	en: 'open'
}
export const closedLabel = {
	de: 'geschlossen',
	it: 'chiuso',
	en: 'closed'
}
export const restaurantLabel = {
	de: 'Restaurant/Gasthof',
	it: 'Ristorante/albergo',
	en: 'Restaurant'
}
export const barLabel = {
	de: 'Bar/Café',
	it: 'Bar/Café',
	en: 'Bar/Coffee shop'
}
export const snackstationLabel = {
	de: 'Jausenstation',
	it: 'Posto ristoro',
	en: 'Snackstation'
}
export const alpLabel = {
	de: 'Alm/Hütte',
	it: 'Malga/baita',
	en: 'Alp'
}
export const distanceLabel = {
	de: 'Entfernung',
	it: 'Distanza',
	en: 'Distance'
}

// PAY ONLINE
export const payIntro = {
	de: 'Sie können Ihre Zimmerrechnung hier bezahlen. Natürlich können Sie auch in bar, mit Karte oder per Banküberweisung zahlen:<br/>IBAN: IT87 Y058 5658 8800 4957 1437 115<br/>SWIFT-BIC: BPAAIT2B049',
	it: 'Paga online',
	en: 'Online pay'
}
export const mandatoryField = {
	de: 'Pflichtfeld',
	it: 'Campo obbligatorio',
	en: 'Mandatory field'
}
export const payOnline = {
	de: 'Online bezahlen',
	it: 'Paga online',
	en: 'Online pay'
}
export const paymentAmount = {
	de: 'Betrag',
	it: 'Importo',
	en: 'Amount'
}
export const paymentDesc = {
	de: 'Buchungsnummer/Name',
	it: 'Numero della prenotazione/nome',
	en: 'Booking number/namename'
}
// NOTIFICATIONS
export const secondsLabel = {
	de: 'Sekunden',
	it: 'secondi',
	en: 'seconds'
}
export const minuteLabel = {
	de: 'Minute',
	it: 'minuto',
	en: 'minute'
}
export const minutesLabel = {
	de: 'Minuten',
	it: 'minuti',
	en: 'minutes'
}
export const hourLabel = {
	de: 'Stunde',
	it: 'ora',
	en: 'hour'
}
export const hoursLabel = {
	de: 'Stunden',
	it: 'ore',
	en: 'hours'
}
export const dayLabel = {
	de: 'Tag',
	it: 'giorno',
	en: 'day'
}
export const daysLabel = {
	de: 'Tagen',
	it: 'giorni',
	en: 'days'
}
// Rental service
export const rentalServiceLabel = {
	de: 'Leihservice',
	it: 'Servizio di noleggio',
	en: 'Rental service'
}
export const calLegendAvailable = {
	de: 'Verfügbar',
	it: 'Disponibile',
	en: 'Available'
}
export const calLegendNotAvailable = {
	de: 'Nicht verfügbar',
	it: 'Non disponibile',
	en: 'Not available'
}
export const bookingLabel = {
	de: 'Jetzt buchen',
	it: 'Prenotare adesso',
	en: 'Book now'
}
export const bookMoreLabel = {
	de: 'Tag hinzufügen',
	it: 'Aggiungi giorno',
	en: 'Add day'
}
export const cartLabel = {
	de: 'In den Warenkorb',
	it: 'Aggiungi al carello',
	en: 'Add to cart'
}
export const selectionLabel = {
	de: 'Buchungsdaten',
	it: 'Dati di prenotazione',
	en: 'Booking dates'
}
export const sendRentalLabel = {
	de: 'Leihbedingungen akzeptieren und Buchung absenden',
	it: 'Accetta le condizioni di noleggio ed invia la prenotazione',
	en: 'Accept rental conditions and send booking'
}
export const noRentalItems = {
	de: 'Derzeit stehen keine Leihartikel zur Verfügung.',
	it: 'Al momento non ci sono articoli a noleggio disponibili.',
	en: 'There are currently no rental items available.'
}
// HIKING
export const searchLabel = {
	de: 'Suchen',
	it: 'Cerca',
	en: 'Search'
}
export const hiking = {
	de: 'Wandern',
	it: 'Hiking',
	en: 'Hiking'
}
export const biking = {
	de: 'Radfahren',
	it: 'Biking',
	en: 'Biking'
}
export const allRegions = {
	de: 'Alle Regionen',
	it: 'Tutte le regioni',
	en: 'All regions'
}
export const allPlaces = {
	de: 'Alle Orte',
	it: 'Tutte i posti',
	en: 'All places'
}
export const schenna = {
	de: 'Schenna',
	it: 'Scena',
	en: 'Schenna'
}
export const meran = {
	de: 'Meran & Umgebung',
	it: 'Merano & dintorni',
	en: 'Merano & surroundings'
}
export const meranCity = {
	de: 'Meran',
	it: 'Merano',
	en: 'Merano'
}
export const hafling = {
	de: 'Hafling - Vöran - Meran 2000',
	it: 'Avelengo - Verano - Merano 2000',
	en: 'Hafling - Vöran - Meran 2000'
}
export const moelten = {
	de: 'Mölten',
	it: 'Meltina',
	en: 'Mölten'
}
export const jenesien = {
	de: 'Jenesien',
	it: 'San Genesio',
	en: 'Jenesien'
}
export const passeiertal = {
	de: 'Passeiertal',
	it: 'Val Passiria',
	en: 'Passeiertal Valley'
}
export const dorfTirol = {
	de: 'Dorf Tirol',
	it: 'Tirolo',
	en: 'Tirolo'
}
export const algund = {
	de: 'Algund',
	it: 'Lagundo',
	en: 'Algund'
}
export const marling = {
	de: 'Marling',
	it: 'Marlengo',
	en: 'Marlengo'
}
export const vigiljoch = {
	de: 'Vigiljoch',
	it: 'San Vigilio',
	en: 'Vigiljoch'
}
export const partschins = {
	de: 'Partschins',
	it: 'Parcines',
	en: 'Partschins'
}
export const ultental = {
	de: 'Ultental',
	it: 'Val d\'Ultimo',
	en: 'Ulten Valley'
}
export const tisensPrissian = {
	de: 'Tisens-Prissian',
	it: 'Tesimo-Prissiano',
	en: 'Tesimo'
}
export const nals = {
	de: 'Nals',
	it: 'Nalles',
	en: 'Nals'
}
export const naturns = {
	de: 'Naturns',
	it: 'Naturno',
	en: 'Naturno'
}
export const stMartin = {
	de: 'St. Martin in Passeier',
	it: 'S. Martino in Passiria',
	en: 'St. Martin in Passeier'
}
export const stLeonhard = {
	de: 'St. Leonhard in Passeier',
	it: 'S. Leonardo in Passiria',
	en: 'St. Leonhard in Passeier'
}
export const tscherms = {
	de: 'Tscherms',
	it: 'Cermes',
	en: 'Tscherms'
}
export const difficultyLevels = {
	de: 'Alle Schwierigkeitsstufen',
	it: 'Titti livelli di difficoltà',
	en: 'All difficulty levels'
}
export const level0 = {
	de: 'leicht',
	it: 'facile',
	en: 'easy'
}
export const level1 = {
	de: 'mittel',
	it: 'di media difficoltà',
	en: 'medium'
}
export const level2 = {
	de: 'schwierig',
	it: 'difficile',
	en: 'difficult'
}
export const noSorting = {
	de: 'Keine Sortierung',
	it: 'Nessun ordinamento',
	en: 'No sorting'
}
export const difficultyLabel = {
	de: 'Schwierigkeitsgrad',
	it: 'Livello di difficoltà',
	en: 'Difficulty level'
}
export const durationLabel = {
	de: 'Dauer',
	it: 'Durata',
	en: 'Duration'
}
export const lengthLabel = {
	de: 'Länge',
	it: 'Lunghezza',
	en: 'Length'
}
export const altimeterLabel = {
	de: 'Höhenmeter',
	it: 'Altimetri',
	en: 'Altimeter'
}
export const detailsLabel = {
	de: 'Details',
	it: 'Dettagli',
	en: 'Details'
}
export const closeDetails = {
	de: 'Details schließen',
	it: 'Chiudere i dettagli',
	en: 'Close details'
}
export const showOnMap = {
	de: 'Karte anzeigen',
	it: 'Mostra mappa',
	en: 'Show map'
}
export const showRoute = {
	de: 'Route anzeigen',
	it: 'Mostra percorso',
	en: 'Show route'
}
export const allCategories = {
	de: 'Alle Kategorien',
	it: 'Tutte le categorie',
	en: 'All categories'
}
export const ourFavorits = {
	de: 'Unsere Favoriten',
	it: 'I nostri consigli',
	en: 'Our favorits'
}
export const favoritLabel = {
	de: 'Favorit',
	it: 'Consiglio',
	en: 'Favorit'
}
export const waalwege = {
	de: 'Waalwege',
	it: 'Sentieri d\'acqua',
	en: 'Waalwege trails'
}
export const hightTrails = {
	de: 'Höhenwege',
	it: 'Alta Via',
	en: 'High trails'
}
export const themedTrails = {
	de: 'Themenwege',
	it: 'Itinerari a tema',
	en: 'themed trails'
}
export const childBuggyTrip = {
	de: 'kinderwagentauglich',
	it: 'adatto per passeggini',
	en: 'suitable for baby carriages'
}
export const bikeTour = {
	de: 'Biketouren',
	it: 'Tour',
	en: 'Routes'
}
export const roadBike = {
	de: 'Rennrad',
	it: 'Bici da corsa',
	en: 'Road bike'
}
export const bikeRentalService = {
	de: 'Radverleih & Service',
	it: 'Noleggio & assistenza',
	en: 'Rental & service'
}
export const eBikeCharger = {
	de: 'E-Bike-Ladestationen',
	it: 'Stazioni ricarica',
	en: 'Charging stations'
}
export const publicHoliday = {
	de: 'Aufgrund des morgigen Feiertages steht heute nur eine reduzierte Brotauswahl zur Verfügung.',
	it: 'A causa della festività di domani, è disponibile oggi solo una selezione ridotta di pane.',
	en: 'Due to tomorrow\'s holiday, only a limited selection of bread is available today.'
}
export const repeatOrder = {
	de: 'Ich möchte diese Bestellung jeden Tag erhalten',
	it: 'Vorrei ricevere questo ordine ogni giorno',
	en: 'Ich möchte diese Bestellung jeden Tag erhalten'
}
export const repeatOrderDesc = {
	de: 'Sie können Ihre Bestellung morgen wieder ändern, indem Sie eine neue aufgeben.',
	it: 'Potete modificare nuovamente il vostro ordine domani inserendolo nuovamente.',
	en: 'You can change your order again tomorrow by re-entering it.'
}
export const repeatOrderCheckout = {
	de: 'Bestellung wiederholen: Ja',
	it: 'Ripetere l\'ordine: Si',
	en: 'Repeat order: Yes'
}
export const cancelOrder = {
	de: 'Meine aktiven Bestellungen stornieren',
	it: 'Annulla i miei ordini attivi',
	en: 'Cancel my active orders'
}